jQuery(document).ready(function(){
    jQuery(function() {
        jQuery('#menu-btn').on('click',
        function(event) {
            event.stopPropagation();
            jQuery('.sub-menu').toggleClass('visible-menu');
            jQuery('.header').toggleClass('menu-opened-lg');
            // jQuery(this).find('.menu-icon').toggleClass('fa fa-bars fa fa-times');
        });
        jQuery('html').click(function() {
            jQuery('.sub-menu').removeClass('visible-menu');
            jQuery('.header').removeClass('menu-opened-lg');
            // jQuery(this).find('.menu-icon').removeClass('fa fa-times');
            // jQuery(this).find('.menu-icon').addClass('fa fa-bars');
        });
    });
    jQuery('#burger').click(function(){
        jQuery('.header').toggleClass('menu-opened');
        // jQuery('.page-content').toggleClass('position-fixed');
        jQuery('#mobile-view').toggleClass('logo-mobile');
        jQuery('body').toggleClass('hidden-page');
    });
    // jQuery(window).scroll(function(){
    //     jQuery('.header.affix').parent('.headerCover').css({"height":"60px","transition":"all 0.3s ease-in-out",});
    // });

    // jQuery('.dropdown-toggle').click(function(){
    //     jQuery(this).find('i').toggleClass('fa-angle-down fa-angle-up');
    // });
    
    jQuery('.animate').addClass("opac0").viewportChecker({
        classToAdd: 'opac1 animated fadeIn',
        offset: 100
    });
    jQuery('.animateDown').addClass("opac0").viewportChecker({
        classToAdd: 'opac1 animated fadeInDown',
        offset: 80
    });
    jQuery('.animateUp').addClass("opac0").viewportChecker({
        classToAdd: 'opac1 animated fadeInUp',
        offset: 80
    });
    jQuery('.animatezoomIn').addClass("opac0").viewportChecker({
        classToAdd: 'opac1 animated zoomIn',
        offset: 80
    });
    //   jQuery('.lanuageOption').click(function(){
    //     jQuery(this).find('i').css();
    // });
    
   //Select_box
    jQuery('.dropdown-menu.languageMenu a').on('click', function(){    
        jQuery(this).parent().parent().parent().find('.dropdown-toggle > span').html($(this).html() + '<i class="icon icon-arrows-down angle-down-size"></i>');    
    });
});


jQuery(document).ready(function () {	
    jQuery('.scrollup').click(function () {
        jQuery("html, body").animate({
            scrollTop: 0
        }, 1500);
        return false;
    });	
});



// SLICK SLIDR
    $(document).ready(function(){
        $('.single-item').slick({
            dots: true,
            adaptiveHeight: false,
            autoplay: false,
            autoplaySpeed: 3000,
            fade: true,
            cssEase: 'ease-in'
        }); 
        $('.single-item').on('afterChange', function(event, slick, currentSlide){
            if (currentSlide == 0) {
                // $('.single-item').slick('slickPause');
                $("#video")[0].src += "&autoplay=0";
            }
            else if (currentSlide == 1) {
                $("#video")[0].src += "&autoplay=0";
                }
        });
    });

   


  