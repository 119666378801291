const token = document.head.querySelector('meta[name="csrf-token"]');
if (!token) console.error('CSRF token not found');

const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': token ? token.content : ''
};

// lodash
window._ = require('lodash');

// jQuery
window.$ = window.jQuery = require('jquery');
window.jQuery.ajaxSetup({ headers });

// Bootstrap
require('bootstrap-sass/assets/javascripts/bootstrap');
// require('bootstrap/dist/css/bootstrap.min.css');

// Axios
window.axios = require('axios');
Object.assign(window.axios.defaults.headers.common, headers);


