window.alertResult = (type, msg, ele = null) => {
    if(ele) ele.addClass('error-border');
    return `<div class="alert alert-${type}" role="alert">
  ${msg}
</div>`;
}

window.removeAlert = (ele = null, redirect = null) => {
    setTimeout(() => {
        $(".alert").fadeOut('slow').remove();
        if(ele) ele.removeClass('error-border');
        if (redirect) location.href = redirect;
    }, 2500,[ele]);
}

$(document).ready(function () {
    removeAlert();
});
