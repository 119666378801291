require('./vendor');
require('../vendors/front/js/custom');
require('../vendors/front/js/jquery.viewportchecker.min');
require('../vendors/front/js/masonry');
require('slick-carousel/slick/slick.min');
require('../vendors/front/js/vissense.min');

// require('./components/ProductView.jsx');
// require('./components/TestView.jsx');
// require('./components/OrderDetail.jsx');
// require('./components/CheckoutView.jsx');
// require('./components/AddressView.jsx');
// require('./components/WishListView.jsx');
// require('./components/SigninInformation.jsx');
// require('./components/PersonalInfoView.jsx');
// require('./components/Cart.jsx');
// require('./components/Listing.jsx');
// require('./components/ThankYou.jsx');
// require('./components/SignIn.jsx');
require('./global');
